
import Vue from 'vue'
import Select from '../../inputs/Select.vue'
import TextField from '../../inputs/TextField.vue'
import _ from 'lodash'
import api from '@root/src/api/api'
export default Vue.extend({
    components: { Select, TextField },
    data: () => ({
        showTable: false,
        dataLoading: false,
        initialTableData: [],
        tableHeaders: [
            { text: 'Пользователь DH', value: 'name' },
            { text: 'ID в yClients', value: 'crmUserId', width: '60%' },
        ],
        opzSettingsData: null,
        currentSettings: [],
        dhUsers: [],
        projectAdmins: [],
        tableData: [],
        projectType: null
    }),
    watch: {
        tableData: {
            immediate: true,
            deep: true,
            handler() {
                let diffArr = []
                this.tableData.forEach(el => {
                    this.initialTableData.forEach(el2 => {
                        if (el.id === el2.id && el.crmUserId !== el2.crmUserId) {
                            diffArr.push(el)
                        }
                    })
                })
                this.$emit('set-opz', diffArr)
            },
        },
    },
    computed: {
        projectId() {
            return this.$router.currentRoute.params.id
        },
        projectIsNetwork(): boolean {
            return this.projectType === 'network'
        },
        yCUsersList(): any[] {
            let data = _.cloneDeep(this.projectAdmins)
            data.push({ crmUserId: null, title: 'Не выбран' })
            return data
        },
    },
    methods: {
        async getOpzSettingsData() {
            const res = await api.gcb2.getAdminsAndDHUsers({ projectId: this.projectId })
            return res
        },
    },
    async mounted() {
        this.dataLoading = true
        this.projectType = await api.project
            .getType({ projectId: this.projectId })
            .then(res => res.data?.type)
        this.opzSettingsData = await this.getOpzSettingsData()
        if (!this.opzSettingsData.error) {
            this.currentSettings = this.opzSettingsData.data.currentSettings || []
            this.dhUsers = this.opzSettingsData.data.dhUsers || []
            this.projectAdmins = this.opzSettingsData.data.projectAdmins.map(el => {
                return {
                    crmUserId: el.id_yc,
                    name: el.name,
                    title: el.title,
                    projectId: el.project_id,
                }
            })

            let data = []
            const namesDhMap = _.keyBy(this.dhUsers, 'id')
            const titleYcMap = _.keyBy(this.projectAdmins, 'crmUserId')
            const dataWithName = _.map(this.currentSettings, item => ({
                ...item,
                name: _.get(namesDhMap, [item.id, 'name'], null),
            }))
            data = _.map(dataWithName, item => ({
                ...item,
                title: _.get(titleYcMap, [item.crmUserId, 'title'], null),
            }))
            this.tableData = _.cloneDeep(data)
            this.initialTableData = _.cloneDeep(data)
        }
        if (this.opzSettingsData.error) {
            this.$store.dispatch('callNotify', 'Ошибка при сохранении настроек')
        }
        this.dataLoading = false
    },
})
