
import {KinescopePlayer} from '@kinescope/vue-kinescope-player';
import Vue from 'vue';
import CloseRoundedIcon from '@/assets/vuetifyIcons/closeRounded.vue'

export default Vue.extend({
    name: 'VideoHelper',
    props: {
        videoId: {
            type: String,
            required: true
        },
        isSized: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {KinescopePlayer, CloseRoundedIcon},
    data: () => ({
        biggerSize: false
    }),
    computed: {},
    methods: {
        close() {
            this.$emit('set-visible', false)
        },
        setBiggerSize(value){
            this.biggerSize = value
        }
    },
    watch: {
        isSized(newVal) {
            this.biggerSize = newVal;
        }
    },
    created() {
        this.biggerSize = this.isSized

    },

})
